import * as React from 'react';
import Layout from '../components/layout';
import Container from '../components/container';

export default function PrivacyPage() {
  return (
    <Layout>
      <Container className="max-w-3xl space-y-10">
        <h1 className="mt-20 text-4xl font-bold text-gray-800">Privacy Policy</h1>

        <div className="max-w-3xl space-y-4 text-xl text-gray-700">
          <p>Your privacy is important to us.</p>
          <p>
            It is Volti Studio’s policy to respect your privacy regarding any information we may
            collect while operating our website. Accordingly, we have developed this privacy policy
            in order for you to understand how we collect, use, communicate, disclose and otherwise
            make use of personal information. We have outlined our privacy policy below.
          </p>

          <ul className="space-y-3 list-disc list-inside">
            <li>
              We will collect personal information by lawful and fair means and, where appropriate,
              with the knowledge or consent of the individual concerned.
            </li>
            <li>
              Before or at the time of collecting personal information, we will identify the
              purposes for which information is being collected.
            </li>
            <li>
              We will collect and use personal information solely for fulfilling those purposes
              specified by us and for other ancillary purposes, unless we obtain the consent of the
              individual concerned or as required by law.
            </li>
            <li>
              Personal data should be relevant to the purposes for which it is to be used, and, to
              the extent necessary for those purposes, should be accurate, complete, and up-to-date.
            </li>
            <li>
              We will protect personal information by using reasonable security safeguards against
              loss or theft, as well as unauthorized access, disclosure, copying, use or
              modification.
            </li>
            <li>
              We will make readily available to customers information about our policies and
              practices relating to the management of personal information.
            </li>
            <li>
              We will only retain personal information for as long as necessary for the fulfilment
              of those purposes.
            </li>
          </ul>

          <p>
            We are committed to conducting our business in accordance with these principles in order
            to ensure that the confidentiality of personal information is protected and maintained.
            Volti Studio may change this privacy policy from time to time at Volti Studio’s sole
            discretion.
          </p>

          <p>
            If you are accessing Volti Studio or its related website or Volti Studio’s other
            Services from the European Union, Asia, or any other region with laws or regulations
            governing personal data collection, use, and disclosure that differ from United States
            laws, please be advised that through your continued use, which is governed by U.S. law
            and this Privacy Policy as incorporated into Volti Studio’s Terms of Service, you will
            be transferring your personal information into the United States and you consent to that
            transfer.
          </p>

          <p>
            If you have any questions or concerns about this Privacy Policy and Volti Studio’s
            privacy practices, you may contact Volti Studio at: hello@voltistudio.com
          </p>
          <p>Last modified: February 12, 2022</p>
        </div>
      </Container>
    </Layout>
  );
}
